import {  Outlet } from 'react-router-dom';
import AppHeader from "./AppHeader";



const SharedLayout = () => {

    return (
        <>
            <AppHeader  />
            <Outlet  />
        </>
    );
};
export default SharedLayout;
