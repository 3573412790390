import { useContext } from 'react';
import { AuthContext } from './AuthContext';
import {Box, Button} from "@mui/material";

const AuthButton = ({ isUserValid}) => {
    const {  login, logout,  } = useContext(AuthContext);
    return (
        <Box sx={{marginTop: '10px'}} >
            {!isUserValid ? (
                <Button
                    onClick={login}
                    variant={'contained'}
                    color={'secondary'}
                >
                    Login
                </Button>
            ) : (
                <Button
                    onClick={logout}
                    variant={'contained'}
                    color={'secondary'}
                >
                    Logout
                </Button>
            )}
        </Box>
    );
};
export default AuthButton;
