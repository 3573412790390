import React, {useState, useEffect} from 'react';

import {Button, Card, CardActions, CardContent, CardMedia, Typography} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {basePath} from './PseudoDatabase'
import {useParams} from "react-router-dom";
import {MainComponentPaper} from "./StyledControls";
import {useBreakpoint} from "./useBreakpoint";
import PropTypes from "prop-types";



export default function Home() {

    let { id } = useParams();
    const [loading,setLoading] = useState(false)
    const [pictures,_setPictures] = useState([]);
    const staticImage =
    {id: 10, title: 'Twelve Apostles' , description: 'The Twelve Apostles', path: "TwelveApostles.jpg", orientation: 'landscape'};


    const setPictures=(pics) => {
        _setPictures(pics);
    }

    useEffect(() => {
        const fetchData = () => {
            console.time("getPics Home");
            setLoading(true);
            fetch('/.netlify/functions/getPictures')
                .then((response) => response.json())
                .then(data => {
                        setPictures(data);
                        setLoading(false);
                        console.timeEnd("getPics Home")
                    }
                )
                .catch(err => {
                        console.log("Error", err);
                    }
                )
        }
        fetchData();

    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);



    if (!pictures || pictures.length===0) {
        return (
            <>
                {loading && (
                    <MainComponentPaper>
                        {/*<DelayedSpinner/>*/}
                        <SimpleMediaCard
                            currentImage={staticImage}
                            noNav
                        />
                    </MainComponentPaper>
                )}
            </>
        )
    } else {
        return (
            <ImageViewer id={id} pictures={pictures}/>
        )
    }
}

function ImageViewer({pictures,id}) {

    const latestImage = (pictures) => {
        if (id) {
            const pic = pictures.find(img => img.id === id);
            console.log("latest by id",id,pictures,pic);
            return pic;
        }
        return pictures.reduce(function(prev, current) {
            return (prev.id > current.id) ? prev : current
        })
    }

    const [currentImage,setCurrentImage] = useState(latestImage(pictures));

    const earliestImage = () => {
        return pictures.reduce(function(prev, current) {
            return (prev.id > current.id) ? current : prev
        })
    }

    const left = (e) => {
        e.preventDefault();
        const id = currentImage.id
        const images = pictures.filter(img => img.id < id);
        if (images.length===0) {
            setCurrentImage(latestImage(pictures));
            return;
        }
        const max = images.reduce(function(prev, current) {
            return (prev.id > current.id) ? prev : current
        })
        setCurrentImage(max);
    }
    const right = (e) => {
        e.preventDefault();
        const id = currentImage.id
        const images = pictures.filter(img => img.id > id);
        if (images.length===0) {
            setCurrentImage(earliestImage());
            return;
        }
        const max = images.reduce(function(prev, current) {
            return (prev.id > current.id) ? current : prev
        })
        setCurrentImage(max);
    }

    return (
        <MainComponentPaper>
            <SimpleMediaCard
                currentImage={currentImage}
                    right={right}
                    left={left}
                />

        </MainComponentPaper>
    )
}




const breakpoints = new Map();
breakpoints.set('xs', {width: '412', });
breakpoints.set('sm', {width: '600', });
breakpoints.set('md', {width: '750', });
breakpoints.set('lg',{width: '1200', });
breakpoints.set('xl', {width: '1500',});

function SimpleMediaCard({currentImage,right,left, noNav}) {

    const breakPoint = useBreakpoint();

    const width = () => {
        return breakpoints.get(breakPoint).width;
    }
    const url = currentImage.orientation==='landscape' ? basePath + 'tr:w-' + width() + '/'  +  currentImage.path : basePath + 'tr:h-' + width() + '/'  +  currentImage.path
    return (
        <div>
            <Card >
                {currentImage.orientation==='landscape' && (
                    <CardMedia
                        sx={{height: 0,
                            paddingTop: '56.25%',
                            objectFit: "contain" }}
                        image={url}
                        title={currentImage.title}
                    />
                )}
                {currentImage.orientation==='portrait' && (
                    <CardMedia
                        sx={{height: 0,
                            paddingTop: '56.25%',
                            objectFit: "contain",
                            marginLeft: '30%',
                            marginRight: '30%'
                        }}
                        image={url}
                        title={currentImage.title}
                    />
                )}

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {currentImage.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {currentImage.description}
                    </Typography>
                </CardContent>
                {noNav===false && (
                    <CardActions>
                        <Button
                            size="small"
                            onClick={left}
                            aria-label={'Previous image'}
                        >
                            <KeyboardArrowLeftIcon />
                        </Button>
                        <Button
                            size="small"
                            onClick={right}
                            aria-label={'Next image'}
                        >
                            <KeyboardArrowRightIcon />
                        </Button>
                    </CardActions>
                )}
            </Card>
        </div>
    );
}

SimpleMediaCard.defaultProps = {
    noNav: false,
}

SimpleMediaCard.propTypes = {
    currentImage: PropTypes.object.isRequired,
    left: PropTypes.func,
    right: PropTypes.func,
    noNav: PropTypes.bool
};



