import React, {useContext} from 'react';
import {
    CssBaseline,
    Toolbar,
    Typography,
    AppBar, Box, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Grow, ListItemIcon, ListItemText, Button
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import GridOnIcon from '@mui/icons-material/GridOn';
import HomeIcon from '@mui/icons-material/Home';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {Link} from "react-router-dom";
import AuthButton from "../security/AuthButton";
import PicAWeekLogo from '../PicAWeekLogo.png'
import MenuIcon from '@mui/icons-material/Menu';
import {AuthContext} from "../security/AuthContext";
import {useBreakpoint} from "./useBreakpoint";

export default function AppHeader() {
    const { user, isTokenValid } = useContext(AuthContext);

    const [open, setOpen] = React.useState(false);

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const bp = useBreakpoint();

    const isSmall = () => {
        if (bp==="xs") return true;
        if (bp==="sm") return true;
        return false;
    }



    return (
                <Grid container >
                    <Grid  xs={12}>
                        <div>
                            <CssBaseline />

                            <AppBar
                                position="static"
                                color="default"
                                sx={{backgroundColor: '#0072bc',

                                    color: 'white',
                                    fontWeight: 'bold',
                                    justifyContent: 'flex-start',}}
                            >
                                {isSmall() ?
                                    <NarrowToolbar
                                        open={open}
                                        handleClose={handleClose}
                                        anchorRef={anchorRef}
                                        handleToggle={handleToggle}
                                    /> : <WideToolbar  user={user} isUserValid={isTokenValid(user)}/>}
                            </AppBar>
                        </div>
                    </Grid>
                </Grid>
    );

}



function WideToolbar({user, isUserValid}) {

    return (
        <Toolbar sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: {
                xs: 428,
                sm: 500,
                md: 800,
                lg: 1100,
                xl: 1500,
            }
        }}>
            <Grid container direction="row" width={'100%'}>
                <Grid
                    xs={4}
                    sx={{
                        display: 'flex',
                    }}

                    justifyContent="left"
                    alignItems={'center'}
                >
                    <img src={PicAWeekLogo} alt={'site logo'} width={236}/>
                </Grid>
                <Grid xs={1} >
                    <Link
                        to={'/'}
                        aria-label={'Home'}
                    >
                        <HomeIcon sx={{margin: 2, color: 'white'}} />
                    </Link>

                </Grid>
                <Grid xs={1} >
                    <Link
                        to={'/grid'}
                        aria-label={'Picture Grid'}
                    >
                        <GridOnIcon sx={{margin: 2, color: 'white'}} />
                    </Link>
                </Grid>
                <Grid xs={1} >
                    <Link
                        to={'/about'}
                        aria-label={'About'}
                    >
                        <InfoOutlinedIcon sx={{margin: 2, color: 'white'}} />
                    </Link>
                </Grid>

                <Grid xs={1} >
                    {isUserValid && (
                        <Link
                            to={'/private/list'}
                            aria-label={'Edit'}
                        >
                            <ListAltIcon sx={{margin: 2, color: 'white'}} />
                        </Link>
                    )}


                </Grid>

                <Grid xs={2} >
                    <Typography
                        component={'div'}
                        align={'right'}
                        sx={{ display: { xs: 'none', md:'block' } }}
                    >
                        <AuthButton  isUserValid={isUserValid}/>
                    </Typography>
                </Grid>
                <Grid xs={2} >
                    {isUserValid && (
                        <Box padding={2}
                             sx={{ display: { xs: 'none', md:'block' } }}
                        >
                            {user?.email}
                        </Box>
                    )}
                </Grid>
            </Grid>


        </Toolbar>
    )
}

function NarrowToolbar({anchorRef,open,handleToggle,handleClose}) {
    return (
        <Toolbar sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%'

        }}>
            <Grid container direction="row">
                <Grid
                    xs={10}
                    sx={{
                        display: 'flex',
                    }}

                    justifyContent="left"
                    alignItems={'center'}
                >
                    <img src={PicAWeekLogo} alt={'site logo'} />
                </Grid>

                <Grid xs={2} >

                    <Typography component={'span'} variant={"h4"}  align={'right'}>
                        <Button
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}

                            size={'small'}
                        >-
                            <MenuIcon  sx={{margin: 2, color: 'white'}} />
                        </Button>

                    </Typography>

                    <HamburgerMenu anchorRef={anchorRef} handleClose={handleClose} open={open} />
                </Grid>



            </Grid>


        </Toolbar>
    )
}

function HamburgerMenu({open,anchorRef,handleClose, }) {



    return (
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{zIndex: 1}}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                >
                    <Paper >
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList>
                                <MenuItem
                                    component={Link}
                                    to={"/"}
                                    onClick={handleClose}
                                    aria-label={'Home'}
                                >
                                    <ListItemIcon>
                                        <HomeIcon  />
                                    </ListItemIcon>
                                    <ListItemText>Home</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to={"/grid"}
                                    onClick={handleClose}
                                    aria-label={'Picture Grid'}
                                >
                                    <ListItemIcon>
                                        <GridOnIcon  />
                                    </ListItemIcon>
                                    <ListItemText>Picture Grid</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to={"/about"}
                                    onClick={handleClose}
                                    aria-label={'About'}
                                >
                                    <ListItemIcon>
                                        <InfoOutlinedIcon  />
                                    </ListItemIcon>
                                    <ListItemText>About</ListItemText>
                                </MenuItem>

                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

