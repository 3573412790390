import { Paper, Typography } from '@mui/material';

import {Watch} from 'react-loader-spinner';
import React from 'react';
import PropTypes from 'prop-types';

// const styles = theme => ({
//
//     root: {
//         margin: theme.spacing(2),
//         padding: theme.spacing(2),
//     },
//     page: {
//         marginLeft: 'auto',
//         marginRight: 'auto',
//         maxWidth: 1200,
//     },
// });

class DelayedSpinner extends React.Component {
    state = {
        showSpinner: false,
    };

    componentDidMount() {
        const { delay } = this.props;
        this.timer = setTimeout(
            () => this.setState({ showSpinner: true }),
            delay,
        );
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const { showSpinner } = this.state;

        return showSpinner && (

            <Paper sx={{marginLeft: 'auto', marginRight: 'auto',
                maxWidth: {
                    'xs': '412px',
                    'sm': '600px',
                    'md': '750px',
                    'lg': '1200px',
                    'xl': '1500px',
                },
                padding: 2}}
            >
                <Typography  component="div"  sx={{
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    maxWidth: {
                        'xs': '200px',
                        'sm': '300px',
                        'md': '350px',
                        'lg': '500px',
                        'xl': '500px',
                    },
                    display: "flex",
                    justifyContent: "center"
                }} >
                    <Watch
                        wrapperStyle={{display: 'inline-block'}}
                        color="#0072bc"
                        height={50}
                        width={50}
                    />
                </Typography>
            </Paper>
        );
    }
}

DelayedSpinner.defaultProps = {
    delay: 750,
}

DelayedSpinner.propTypes = {
    delay: PropTypes.number,

};

export default DelayedSpinner;
