import { Link } from 'react-router-dom';
import {MainComponentPaper} from "./StyledControls";
import {Typography} from "@mui/material";

const Error = () => {
    return (
        <MainComponentPaper>
            <section className='section'>
                <Typography variant={'h2'}>404</Typography>
                <Typography variant={"h4"} >Page not found. </Typography>
                <Link to='/'>back home</Link>
            </section>
        </MainComponentPaper>
    );
};
export default Error;
