import {basePath} from '../components/PseudoDatabase'
import {
    ImageList, ImageListItem, Box, Button
} from '@mui/material';
import React, {useState, useEffect, useRef, useCallback} from "react";
import {useBreakpoint} from "../components/useBreakpoint";
import DelayedSpinner from "../components/DelayedSpinner";
import {MainComponentPaper} from "../components/StyledControls";



const breakpoints = new Map();
breakpoints.set('xs', {width: '412px', cols: 2, rowHeight: 204, imageTranslate: 'tr:w-200,h-200'});
breakpoints.set('sm', {width: '600px', cols: 3, rowHeight: 200, imageTranslate: 'tr:w-200,h-200'});
breakpoints.set('md', {width: '750px', cols: 3, rowHeight: 250, imageTranslate: 'tr:w-250,h-250'});
breakpoints.set('lg',{width: '1200px', cols: 4, rowHeight: 300, imageTranslate: 'tr:w-300,h-300'});
breakpoints.set('xl', {width: '1500px', cols: 5, rowHeight: 300, imageTranslate: 'tr:w-300,h-300'});


export default function InfiniteLoadPictureGrid() {
    const [items,setItems] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [page,setPage] = useState(1);
    const loadLatch = useRef(false);
    const [hasMore,setHasMore] = useState(true)



    const fetchData =  useCallback(() => {
        setLoading(true);
        setError(null);




        fetch(`/.netlify/functions/getPagedPictures?page=${page}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: "GET",
            })
            .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    return Promise.reject(Object.assign({}, res, {
                        status: res.status,
                        statusText: res.statusText,
                    }));
                }
            ).then((res) => {
            setItems(prevItems => [...prevItems, ...res]);
            setPage(prevPage => prevPage + 1);
        })
            .catch((err) => {
                console.log('error',err);

                if (err.status===404) {
                    console.log('404');
                    setHasMore(false)
                } else {
                    setError(err);
                }

            })
            .finally(() =>{
                    setLoading(false);
                }
            );


    },[page]);

    useEffect(() => {
        if (!loadLatch.current) {
            fetchData();
            loadLatch.current =true;
        }

    }, [fetchData]);

    const breakPoint = useBreakpoint();

    const columns = () => {
        return breakpoints.get(breakPoint).cols;
    }
    const width = () => {
        return breakpoints.get(breakPoint).width;
    }
    const rowHeight = () => {
        return breakpoints.get(breakPoint).rowHeight;
    }



    return (
        <MainComponentPaper>
            {loading && (
                <DelayedSpinner />
            )}

            {error && error.statusText}
            <InfiniteLoad
                dataLength={items.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>You have reached the end of the road. No more pictures.</b>
                    </p>
                }
            >
                <ImageList

                    sx={{
                    width: `${width()}` }}
                           rowHeight={rowHeight()}
                           cols={columns()}>
                    {items.map((item) => (
                        <ImageListItem key={item.id}>
                            <a href={`/picture/${item.id}`} aria-label={'View ' + item.title} >
                                <Box
                                    border={0}
                                    sx={{
                                        content: {
                                            xs: "url(" + basePath + 'tr:w-202,h-204/'  +  item.path +")",
                                            sm: "url(" + basePath + 'tr:w-200,h-200/'   +  item.path +")",
                                            md: "url(" + basePath + 'tr:w-250,h-250/'  +  item.path +")",
                                            lg: "url(" + basePath + 'tr:w-300,h-300/' +  item.path +")",
                                            xl: "url(" + basePath + 'tr:w-300,h-300/'    +  item.path +")",
                                        },
                                        display: 'inline-block'}}
                                    component={'img'}
                                    padding={0}

                                >
                                </Box>
                            </a>
                        </ImageListItem>
                    ))}
                </ImageList>
            </InfiniteLoad>


        </MainComponentPaper>
    )
}

function InfiniteLoad({next,hasMore, endMessage,children}) {

    return (
        <>
            {children}
            { hasMore && (
                <p style={{ textAlign: 'center' }}>
                    <Button
                        onClick={next}
                        >Load More
                    </Button>
                </p>
            )}
            { ! hasMore && (
                endMessage
            )}
        </>
    )


}
