import React, {Suspense, useContext} from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import LoadingAdvice from "./components/LoadingAdvice";
import SharedLayout from "./components/SharedLayout";
import Home from "./components/Home";
import Error from "./components/Error";
import {AuthContext} from './security/AuthContext';
import About from "./components/About";

import ListPictures from "./pictures/ListPictures";
import InfiniteLoadPictureGrid from "./pictures/InfiniteLoadPictureGrid";


const ProtectedRoute = ({
                            user,
                            isTokenValid,
                            redirectPath = '/',
                            children,
                        }) => {

    if (! isTokenValid(user)) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};

function App() {
    const { user, isTokenValid } = useContext(AuthContext);
  return (

        <BrowserRouter>
            <Suspense fallback={<LoadingAdvice/>}>
              <Routes>
                <Route path="/" element={<SharedLayout />} >
                  <Route index element={<Home />} />
                  <Route path='/picture/:id' element={<Home />} />
                  <Route path='/grid' element={<InfiniteLoadPictureGrid />} />
                  <Route path='/about' element={<About />} />
                  <Route path="*" element={<Error />} />
                  <Route
                        path='/private/list'
                        element={
                            <ProtectedRoute user={user} isTokenValid={isTokenValid}>
                                <ListPictures />
                            </ProtectedRoute>
                      } />
                </Route>
              </Routes>
            </Suspense>
        </BrowserRouter>

  );


}

export default App;
