import React, {useContext, useEffect, useReducer, useRef, useState} from "react";
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import PropTypes from "prop-types";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {basePath} from '../components/PseudoDatabase'
import Grid from '@mui/material/Unstable_Grid2';

import {AuthContext} from "../security/AuthContext";
import {MainComponentPaper} from "../components/StyledControls";

export default function ListPictures() {
    const [pictures,setPictures] = useState([]);
    const [counter, setCounter] = useState(0)
    const [addMode,setAddMode] = useState(false);
    const [error,setError] = useState(null);
    const { user} = useContext(AuthContext);
    const [addPicture, setAddPicture] = useState(null);

    useEffect(() => {
        console.time("fetch pictures");
        fetch('/.netlify/functions/getPictures')
            .then((response) => response.json())
            .then(data => {
                setPictures(data);
            }
            )

            .catch(err => {
                    console.log("Error", err);
                    setError(err);
                }
            )
    }, [counter]);

    const save = (picture) => {
        console.log("Save Picture",picture)
        setError(null)


        fetch("/.netlify/functions/upsertPicture",
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user?.token?.access_token}`
                },
                method: "POST",
                body: JSON.stringify(picture)
            })
            .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                return Promise.reject(Object.assign({}, res, {
                    status: res.status,
                    statusText: res.statusText,
                }));
                }
            ).then((responseJson) => {
                    console.log('Response', responseJson)
                    setCounter(counter+1);
                    setAddMode(false);
                    setAddPicture(null);
                })
                    .catch((err) => {
                        console.log('error',err);
                        setError(err);
                    });



    }

    const handleDelete =(id) => {
        console.log('Delete',id)
        fetch('/.netlify/functions/deletePicture/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                     'Authorization': `Bearer ${user?.token?.access_token}`
                },
                method: "DELETE",
            })
            .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    return Promise.reject(Object.assign({}, res, {
                        status: res.status,
                        statusText: res.statusText,
                    }));
                }
            ).then(() => {
            setCounter(counter+1);
            setAddMode(false)
        })
            .catch((err) => {
                console.log('error',err);
                setError(err);
            });

    }

    const add = () => {
        setAddPicture(initialState);
        setAddMode(true);
    }

    const sort = (pictures) => {
        if (pictures) {
            return pictures.sort((a, b) => {
                if (a.id > b.id) {
                    return -1;
                }
                if (a.id < b.id) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
        }
        return [];
    }

    // useEffect(() => {
    //     console.log('reauth')
    //     reauthenticate();
    // },[])

   return (
        <MainComponentPaper >
            <Box padding={1} sx={{width: '1000px', marginLeft: 'auto', marginRight: 'auto'}} >
                <Typography color={'#F44336'} fontWeight={'bold'} margin={2}>
                    {error?.status}&nbsp;{error?.statusText}
                </Typography>
                <NewPicture onAdd={add} addMode={addMode}/>
                <PictureCreate
                    error={error}
                    onSave={(e) => save(e)}
                    createMode={addMode}
                    pictureData={addPicture}
                />
                <PictureTable
                    dataObject={sort(pictures)}
                    onSave={(e) => save(e)}
                    addMode={addMode}
                    onDelete={handleDelete}
                    error={{error}}
                />
            </Box>
        </MainComponentPaper>
    )
}

function NewPicture({onAdd, addMode}) {
    return (
        <Box sx={{display: 'flex', alignItems: 'flex-end'}} >
            <Button
                onClick={onAdd}
                variant={'contained'}
                disabled={addMode}
            ><AddIcon /></Button>
        </Box>
    )
}

function PictureTable({onDelete,dataObject, onSave, addMode, error})  {
    const [size,setSize] = useState(0);
    const [data,setData] = useState([]);
    const [page,setPage] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(10);

    const openRows = useRef(new Map());
    const [openSelection,setOpenSelection] = useState([]);

    const handleOpenRow = (id) => {
        if (openRows.current.has(id)) {
            openRows.current.delete(id)
        } else {
            openRows.current.set(id,id)
        }
        setOpenSelection(Array.from(openRows.current.keys()));
    }

    const handleChangePage = (event, page) => {
        setPage(page);
    };

    function handleChangePageSize(event) {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10))
    }



    useEffect(() => {
        if (addMode) {
            handleOpenRow(null);
        }
    },[addMode])

    const handleSave = (pic) => {

        onSave(pic);

        if (error !==null) {

            handleOpenRow(pic.id);
        }
    }


    useEffect(() => {
        setData(dataObject)
        setSize(dataObject?.length)
    },[dataObject,page])



    return (




        <TableContainer >
            <Table size="small" aria-labelledby="Picture List"  >
                <TableHead>
                    <TableRow>
                        <TableCell >

                        </TableCell>
                        <TableCell >
                            Title
                        </TableCell>
                        <TableCell >
                            Description
                        </TableCell>
                        <TableCell >
                            Picture
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {data && (rowsPerPage > 0
                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : data
                    ).map((n) => {
                        return (
                            <Row
                                key={n.id}
                                rowData={n}
                                openSelection={openSelection}
                                onOpen={(p) => handleOpenRow(p)}
                                onSave={handleSave}
                                onDelete={onDelete}
                                error={error}
                            />
                        );
                    })}

                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={size}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
                rowsPerPageOptions={[10,25,50,100]}
            />
        </TableContainer>


    );
}

PictureTable.propTypes = {
    paging: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired
    }),
    dataObject: PropTypes.array.isRequired,
};

function Row(props) {
    const {rowData, index, openSelection, onOpen, onDelete, onSave, error} = props;
    const open = (openSelection.find(p => p === rowData.id) !== undefined)


    return (
        <>
            <TableRow
                key={index}

            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => onOpen(rowData.id)}
                        style={{padding: 0,height: 20} }
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell >
                    {rowData.title}
                </TableCell>

                <TableCell >
                    {rowData.description}
                </TableCell>
                <TableCell ><Box
                    border={0}
                    sx={{
                        content: "url(" + basePath + 'tr:w-100,h-100/' + rowData.path + ")"
                    }}
                    component={'img'}
                    padding={0}

                >
                </Box>
                </TableCell>


            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} >
                            <EditablePicture
                                pictureData={rowData}
                                closed={open}
                                onSave={onSave}
                                onDelete={onDelete}
                                error={error}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )

}

Row.propTypes = {
    mergeSelection: PropTypes.array,
    openSelection: PropTypes.array.isRequired,
    onOpen: PropTypes.func.isRequired
};

const initialState = {
    id: null,
    title: '',
    description: '',
    orientation: 'landscape',
    path: '',
}


function pictureReducer(state, action) {
    switch (action.type) {
        case "SET_USER_FIELD":
            return  {
                ...state,
                [action.fieldName]: action.value,
                dirty: true,

            };
        case "DATA_FETCH_SUCCESS":
            return {  ...action.data,
                dirty: false,
            };
        case "CLEAR_DIRTY":
            return {...state,
                dirty: false}
        case "RESET":
            return initialState;
        default:
            return state;
    }
}

function PictureCreate({createMode,pictureData,onSave,error}) {

    if (! createMode) {
        return null;
    } else {
        return (
            <EditablePicture pictureData={pictureData} onSave={onSave} error={error} />
        )
    }
}

function EditablePicture({pictureData, onSave, onDelete, error}) {
    const [picture, dispatch] = useReducer(pictureReducer, (pictureData? pictureData : initialState));
    const {user, isTokenValid} = useContext(AuthContext);
    const save = () => {
        onSave(picture);
    }

    const handleChange = (event) => {
        const { target: { name, value} } = event;
        dispatch(
            {   type: 'SET_USER_FIELD',
                fieldName: name,
                value:  value
            });
    };

    const canSave = isTokenValid(user);

    return (
        <Box padding={1} border={1}>
            <Grid spacing={1} container>
                {error && (
                    <Grid xs={12}>
                        <Typography color={'#F44336'} fontWeight={'bold'} >
                            {error.message}
                        </Typography>
                    </Grid>
                )}
                <Grid xs={11}/>
                <Grid xs={1} >
                    <DeleteButton
                        onDelete={onDelete}
                        picture={picture}
                        disabled={! canSave}
                    >
                        Delete
                    </DeleteButton>
                </Grid>
                <Grid xs={3}>Title</Grid>
                <Grid xs={9}>
                    <TextField
                        name={'title'}
                        required
                        fullWidth
                        value={picture.title}
                        variant="outlined"
                        helperText={'Title *'}
                        onChange={handleChange}
                        size={'small'}
                        disabled={! canSave}
                    />
                </Grid>
                <Grid xs={3}>Description</Grid>
                <Grid xs={9}>
                    <TextField
                        name={'description'}
                        required
                        fullWidth
                        multiline
                        rows={4}

                        value={picture.description}
                        variant="outlined"
                        helperText={'Description'}
                        onChange={handleChange}
                        size={'small'}
                        disabled={! canSave}
                    />
                </Grid>
                <Grid xs={3}>Orientation</Grid>
                <Grid xs={9}>
                    <OrientationRadioButtons
                        name={'orientation'}
                        onChange={handleChange}
                        value={picture.orientation}
                    />
                </Grid>
                <Grid xs={3}>Picture</Grid>
                <Grid xs={9}>
                        <TextField
                            name={'path'}
                            required
                            fullWidth
                            value={picture.path}
                            variant="outlined"
                            helperText={'Path'}
                            onChange={handleChange}
                            size={'small'}
                            disabled={! canSave}
                        />

                </Grid>
                <Grid xs={11} />
                <Grid xs={1}>
                    <Button
                        onClick={save}
                        disabled={! canSave}
                        variant={'contained'}
                    >Save</Button>

                </Grid>

            </Grid>
        </Box>
    )

    function DeleteButton({picture, onDelete, disabled}) {
        const [showDelete,setShowDelete] = useState(false);

        const handleClose = () => {
            setShowDelete(false)
        }

        return (
            <>
                <Button
                    onClick={() => setShowDelete(true)}
                    disabled={disabled}
                >
                    <DeleteIcon />
                </Button>
            {showDelete && (
                <PictureDelete
                    onDelete={onDelete}
                    onClose={handleClose}
                    picture={picture}
                />

            )}
            </>
        )

    }

    function PictureDelete({picture, onDelete, onClose}) {

        const handleDelete=() => {
            onDelete(picture.id)
        }

        return (
            <div >
                <Dialog
                    open={true}
                    onClose={onClose}
                >
                    <DialogTitle>
                        Delete Picture?
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant={"subheading"}  >
                            Remove the picture {picture.title}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            size={'small'}
                            aria-label="Cancel"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleDelete}
                            color="secondary"
                        >
                            Delete
                        </Button>
                    </DialogActions>

                </Dialog>
            </div>
        );
    }

    function OrientationRadioButtons({value,onChange, name}) {
        const [selectedValue, setSelectedValue] = React.useState(value);
        const handleChange = (event) => {
            setSelectedValue(event.target.value);
            onChange({target: {name: name, value: event.target.value}})
        };

        return (
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="orientation-group"
                    defaultValue="landscape"
                    name={name}
                    onChange={handleChange}
                    value={selectedValue}
                >
                    <FormControlLabel value="landscape" control={<Radio />} label="Landscape" />
                    <FormControlLabel value="portrait" control={<Radio />} label="Portrait" />
                </RadioGroup>
                <FormLabel id="orientation-label">Orientation</FormLabel>
            </FormControl>
        );
    }
}

