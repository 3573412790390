export  const imageList = [
    {id: 1, title: 'Buoys' , description: 'Getting ready for the pier to pub at lorne', path: "Bouys.jpg", orientation: 'landscape'},
    {id: 2, title: 'Close of day' , description: 'Tasmania at the end of day', path: "CloseOfDay.jpg", orientation: 'landscape'},
    {id: 3, title: 'End of the road' , description: 'A cart that has just about given uo the ghost. SA', path: "EndofTheRoad.jpg", orientation: 'landscape'},
    {id: 4, title: 'Lorne Beach Flags' , description: 'Competition for surf life savers', path: "LorneBeachFlags.jpg", orientation: 'landscape'},
    {id: 5, title: 'Mountain stream' , description: 'A mountain stream in New Zealand', path: "MountainStreamNewZealand.jpg", orientation: 'landscape'},
    {id: 6, title: 'Night Sky' , description: 'The night sky in the desert of SA', path: "NightSkySA.jpg", orientation: 'portrait'},
    {id: 7, title: 'Pike River' , description: 'Early morning on the Pike River in tasmania', path: "PikeRiver.jpg", orientation: 'landscape'},
    {id: 8, title: 'Rabbit Island' , description: 'Sunset at Rabbit Island, Nelson New ZZealand', path: "RabbitIslandNewZealand.jpg", orientation: 'portrait'},
    {id: 9, title: 'Tahuna Beach' , description: 'At Nelson New Zealand', path: "TahunaBeachNewZealand.jpg", orientation: 'portrait'},
    {id: 10, title: 'Twelve Apostles' , description: 'The Twelve Apostles', path: "TwelveApostles.jpg", orientation: 'landscape'},
    {id: 11, title: 'Vineyard' , description: 'Vineyard in New Zealand', path: "VineyardNelsonNewZealand.jpg", orientation: 'landscape'},
    {id: 12, title: 'Wild Sea' , description: 'A wild seascape. Tasmania', path: "WildSea.jpg", orientation: 'portrait'},
    {id: 13, title: 'Yarra Dusk' , description: 'Yarra river at dusk. Melbourne', path: "YarraDusk.jpg", orientation: 'landscape'},
    {id: 14,  title: 'Boards' , description: 'Nipper boards on the beach waiting for a competition to start', path: "Boards.jpg", orientation: 'landscape'},
    {id: 15, title: 'Shell' , description: 'A shell on a beach in Nelson New Zealand', path: "Shell.jpg", orientation: 'landscape'},
    {id: 16, title: 'Cactus' , description: 'Other wordly plants', path: "Cactus.jpg", orientation: 'landscape'},
    {id: 17, title: 'Maheno' , description: 'Wreck of the Maheno on K\'gari Island (Fraser island), ', path: "Maheno.jpg", orientation: 'landscape'},
    {id: 18, title: 'Bee' , description: 'Where the bee sucks there suck I, in the cowslips bell I lie. Well not quite a cosslip', path: "Bee.jpg", orientation: 'portrait'},
    {id: 19, title: 'Sand Dune' , description: 'A wind blown sand dune on K\'gari Island', path: "SandDune.jpg", orientation: 'landscape'},
    {id: 20, title: 'Pier' , description: 'The remains of the Pier in Port Phillip Bay', path: "Pier.jpg", orientation: 'landscape'},
    {id: 21, title: 'ANZ' , description: 'ANZ head office. Melbourne', path: "ANZ.jpg", orientation: 'landscape'},
    {id: 22, title: 'Bay of Fires' , description: 'Moonlight pathway. Bay of fires. Tasmania', path: "BayOfFires.jpg", orientation: 'landscape'},
    {id: 23, title: 'Cradle Mountain' , description: 'Cradle Mountain Tasmania', path: "CradleMountain.jpg", orientation: 'landscape'},
    {id: 24, title: 'House Barra' , description: 'The famous house used in the Cover of Diesel and Dust', path: "DiesealAndDust.jpg", orientation: 'landscape'},
    {id: 25, title: 'Flinders ruin' , description: 'Ruined house Flinders Ranges', path: "FlindersRuin.jpg", orientation: 'landscape'},
    {id: 26, title: 'Forest' , description: 'Forest in Tasmania', path: "Forest.jpg", orientation: 'landscape'},
    {id: 27, title: 'Waterfall' , description: 'Waterfall in Tasmania', path: "Waterfall.jpg", orientation: 'landscape'},
    {id: 28, title: 'Boat Shed' , description: 'The boat shed at Cradle Mountain', path: "CradleMountainBoatShed.jpg", orientation: 'landscape'},
    {id: 29, title: 'Flinders' , description: 'Flinders Ranges', path: "Flinders.jpg", orientation: 'landscape'},
    {id: 30, title: 'Bridge' , description: 'Webb bridge in Southbank Melbourne', path: "Bridge.jpg", orientation: 'landscape'},
    {id: 48, title: 'Autumn' , description: 'The turn is on', path: "Leafs.jpg", orientation: 'landscape'},
    {id: 49, title: 'Rose' , description: 'This old fashioned rose is on my street. Its one of the first to come out in spring and this is the last of the roses in autumn.', path: "Rose.jpg", orientation: 'landscape'},
    {id: 50, title: 'Fitzroy Scene' , description: 'Late lunch, Fitzroy', path: "FitzroyScene.jpg", orientation: 'landscape'},
]

export const basePath = 'https://ik.imagekit.io/do2bgahgwo/'
