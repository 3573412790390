import {useState, createContext, useEffect} from 'react';
import netlifyIdentity from 'netlify-identity-widget';
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const nUser = netlifyIdentity.currentUser();
    useEffect(() => {
        // initialize netlify identity
        netlifyIdentity.init();


    }, []);

    useEffect(() => {
        // update user state on init event
        netlifyIdentity.on('init', (user) => {
            setUser(user);
        });

    }, []);

    useEffect(() => {
        // update user state after login
        netlifyIdentity.on('login', (user) => {
            console.log('Login',user);
            setUser(user);
            // close the modal
            netlifyIdentity.close();
        });
    }, []);

    useEffect(() => {
            setUser(netlifyIdentity.currentUser());
    }, [nUser]);



    const login = () => {
        netlifyIdentity.open('login');
    };

    const logout = () => {
        netlifyIdentity.logout();
        setUser(null);
    };

    const isTokenValid = (user) => {
        const valid =   (user?.token?.expires_at >= Date.now());
        console.log("Valid",user,valid);
        return valid;
    }

    const contextValues = { user, login, logout, isTokenValid };
    return (
        <AuthContext.Provider value={contextValues}>
            {children}
        </AuthContext.Provider>
    );
};
