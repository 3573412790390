import {Box, Typography} from "@mui/material";

export default function About() {

    return (
        <Box
            sx={{margin: {
                xs: 1,
                sm: 5,
            },}}
        >
            <Typography variant={'h4'}>About Picture a Week</Typography>
            <p><i>Picture a Week</i> is an idea that is wavering between being a list of my better photos and an attempt to take a picture a week.</p>
            <p>It is going to be a curated list in that I might add photos, that are of the week, and I might take photos away if I decide that they don't make the cut.</p>
            <p>There is a lot of codda woudda hand waving here. Truth is I am not sure how this will develop. It's an experiment both photographically and software wise.</p>
            <p>All photos are mine, they are mostly taken with whatever camera comes to hand. Mostly Canon cameras or the pocket cam of choice an iPhone.</p>
            <p>Picture a week is also an attempt to build using free stuff on the web, and to be serverless and all those buzz word things. I am building this very much in
                an agile style. ie build a bit, then improve a bit then add a feature and so on. So over time it should be better.</p>
            <p>Currently the site uses &hellip;</p>
                <ul>
                    <li>React to build the site</li>
                    <li>Material UI for the look of most things</li>
                    <li>Netify to host the react app</li>
                    <li>Bit bucket for Git and the CI/CD pipeline to Netify</li>
                    <li>ImageKit.io for the image hosting and image manipulation</li>
                    <li>Cockroach DB for hosting a Postgres database</li>
                    <li>Prisma for linking the db to the react app</li>
                </ul>
         
        </Box>
    )
}
