import {styled} from "@mui/system";
import Paper from "@mui/material/Paper";


// width: {
//     xs: 428,
//         sm: 500,
//         md: 800,
//         lg: 1100,
//         xl: 1500,
// }
export const MainComponentPaper = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        minWidth: '428px',
        width: 428,
        padding: '0',
        border: 0,
        boxShadow: 'none',
    },
    [theme.breakpoints.up("sm")]: {
        minWidth: '500px',
        width: 500,
        padding: '0',
        border: 0,
        boxShadow: 'none',
    },
    [theme.breakpoints.up("md")]: {
        minWidth: '910px',
        width: 910,
        padding: '15px',
    },
    [theme.breakpoints.up("lg")]: {
        minWidth: '1220px',
        width: 1220,
        padding: '15px',
    },
    [theme.breakpoints.up("xl")]: {
        minWidth: '1600px',
        width: 1600,
        padding: '15px',
    },
  //  minHeight: 'calc(100vh - 120px)',
    marginLeft: 'auto',
    marginRight: 'auto',

}));
